<template>
  <NuxtLink to="/course/99-cypress-tips">
    <div class="bg-tangerine dark:bg-gray-800 p-4 sticky top-0 z-40 block lg:hidden">
      <div class="text-lg md:text-xl lg:text-xl font-extrabold">
        <p class="text-center">
          Check out my newly released course <span class="text-white">99 Cypress.io Tips</span>
        </p>
      </div>
    </div>
  </NuxtLink>
</template>